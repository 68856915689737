.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

p {
    letter-spacing: .4px;
    word-spacing: .4px;
    color: #555;
    &.lead {
        font-size: 18px;
    }
}

img.pull-right,
img.pull-left {
    float: none !important;
    margin: 5px auto 15px;
    @extend .img-responsive;
}

img.pull-left-right {
    float: left !important;
    margin: 5px auto 15px;
    @extend .img-responsive;
    @media (min-width: $screen-sm-min) {
        float: right !important;
        margin-left: 30px;
    }
}

img.pull-right {
    @media (min-width: $screen-sm-min) {
        float: right !important;
        margin-left: 30px;
    }
}

img.pull-left {
    @media (min-width: $screen-sm-min) {
        float: left !important;
        margin-right: 30px;
    }
}

.thumbnail,
.wp-caption {
    border: 1px solid rgba(221, 221, 221, .27);
    border-radius: 0;
    background-color: rgba(246, 246, 246, .36);
}

.btn-primary {
    font-family: $headings-font-family;
    font-size: 13px;
    padding: 11px 28px 12px;
    transition: 1s;
    letter-spacing: -.4px;
    text-transform: uppercase;
    color: #001b20;
    border: 1px solid #0fd8ff;
    border-radius: 25px;
    outline: none;
    background-color: #00d5ff;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, .18);
    @media (min-width: $screen-sm-min) {
        padding: 17px 40px 18px;
    }
    &:hover {
        border: 1px solid #00b8dc;
        background-color: #00abcd;
    }

    &.sm {
    	padding: 8px 22px 9px;
    }
}

#map {
    position: relative;
    width: 100%;
    height: 300px;
    @media (min-width: $screen-sm-min) {
        height: 500px;
    }
}

#map-container {
    @media (max-width: $screen-sm-max) {
        margin: 30px 0;
    }
    @media (min-width: $screen-sm-min) {
        position: absolute;
        z-index: 1;
        right: 0;
        margin-top: 120px;
        padding: 20px;
        background-color: #fff;
        box-shadow: 0 1px 10px rgba(0, 0, 0, .19);
    }
    @media (min-width: $screen-md-min) {
        padding: 40px;
    }
}

@media (max-width: $screen-xs-max) {
    .bg-for-img {
        background: rgba(255,255,255,.85) !important;
    }
}

aside {
	    background: #F9F9F9;

	    @media (min-width: $screen-sm-min) {
	        margin-top: 73px;
	    }

	    @media (min-width: $screen-lg-min) {
		    padding-left: 45px !important;
		    padding-right: 45px !important;
		    margin-top: 75px !important;	    
	    }

}