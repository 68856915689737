/*HEADER*/

body > div > header > nav {
	width: 100%;
	border: none!important;
	@media screen and (min-width: 768px) {
		border-bottom: 1px solid rgba(126, 132, 128, 0.3)!important;
	}

	#bs-example-navbar-collapse-1 > ul {

		li > a {
			color: #FFF;
			font-family: "Montserrat";
			text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.47);
		}
	}
}


@media screen and (min-width: 1230px) {

	#bs-example-navbar-collapse-1 > ul {
		padding-right: 14px;

		li > a {
			padding-left: 10px;
			padding-right: 10px;
		}
	}		
}


@media screen and  (max-width: 767px) {

	#bs-example-navbar-collapse-1 > ul {
		padding-right: 0px;

		li > a {
			padding: 15px 0;
		}
	}		
}





.wrap > .header > nav {
	position: absolute;
	background-color: transparent;
}


.popover-content {
  font-size: 12px;
  color: rgb(53, 53, 53);
  text-align: center;
  font-weight:bold;
  padding: 9px 10px 12px;
}

.popover-title {
	font-family: $headings-font-family;
	text-align: center;
	font-size: 14px;
	font-weight: bolder;
	text-align: center;
	border: none;
	padding: 12px 20px 0;
}

/*SECTION-1*/

.section-one {
	background-image: url(../../dist/images/Background.jpg);
	background-repeat: no-repeat;
	background-size: cover;

	.s1-div1 {
		line-height: 55px;

		.s1-p-h3 {
			color: #FFF;
			font-family: "Montserrat";
			font-weight: bold;
			text-shadow: 0px 1px 1px #333;
			margin-bottom: 0px;
			letter-spacing: -1px;
		}
	}
	
	.s1-div2 {
		margin-top: 30px;

		.s1-p-p {
			color: #FFF;
			font-weight: 100;

			line-height: 1.556;
			text-align: left;
		}
	}

	.s1-div3 {
		margin-top: 15px;

		a {
			text-decoration: none!important;
		}

	}
}

body > footer > div > div > div > div > ul > li > a:hover {
	background-color: #FFF!important;
	outline: none;
}

body > footer > div > div > div > div > ul > li > a:focus {
	background-color: #FFF!important;
	border-bottom: 2px solid #00D5FF;
	outline: none;
}


/*SECTION-2*/

.section-two {

	.icon-1 {
		display: block;
		text-align: center;
	}

	.s2-p-h3 {
		margin-top: 45px;
		font-size: 19px;
		font-family: "Montserrat";
		font-weight: bold;
		text-align: center;
		word-spacing: 2px;
	}

	.s2-p-p {
		margin-top: 10px;
		font-size: 13px;
		font-family: "Montserrat";
		text-align: center;
		word-spacing: 1px;
		line-height: 17px;
	}
}

/*SECTION-3*/

.section-three {
	background-color: #EFF0F4;

	.s3-div1 {
		padding-top: 45px;

		.s3-p-p {
			font-family: "Montserrat";
			color: #A8ACB9;
			font-weight: bold;
			line-height: 1.2;
			margin-right: -10px;
			word-spacing: -0.4px;
			letter-spacing: -0.4px;
		}
	}

	.s3-div5 {
		padding-top: 25px;
	}

	.s3-div6 {
		padding-top: 30px
	}
}

/*SECTION-4*/

.section-four {
	background-image: url(../../dist/images/Background2.jpg);
	background-repeat: no-repeat;
	position: relative;
	background-size: cover;

	.s4-div2 {

		.s4-p-h3 {
			font-family: "Montserrat";
			font-weight: bold;
		}

		.pict-2, .pict-3 {
			margin-top: 15px;
		}

		.s4-p-p {
			line-height: 1.333;
			padding-top: 10px;
			letter-spacing: 0.4px;
		}

		.s4-p-p2 {
			font-family: "Montserrat";
			line-height: 1.286;
		}
	}
}

/*SECTION-5*/

.section-five {
	background-color: #FAFAFA;

	.s5-div1 {

		.s5-p-h3 {
			color: #003944;
			font-family: "Montserrat";
			font-weight: bold;
			text-align: center;
		}
	}

	.s5-div2 {

		.s5-p-p {
			color: #003944;
			margin-top: 30px;
			font-family: "Montserrat";
			line-height: 1.5;
			text-align: center;
			letter-spacing: 0.2px;
		}
	}

	.s5-div3 {

		a {
			text-decoration: none!important;
		}
	}
}



@media screen and (min-width: 992px) {
	.s4-p-h3 {
		color: #003944;
	}

	.s4-p-p {
		color: #003944;
	}

	.s4-p-p2 {
		color: #003944;
	}

	.text-1 {
		margin-top: 30px;
	}

	.text-2, .text-3 {
		margin-top: 15px;
	}

	.pict-1 {
		margin-top: 15px;
	}
	
	.section-two {
		padding-top: 100px;
		padding-bottom: 90px;
	}

	.section-three {
		padding-top: 70px;
		padding-bottom: 70px;
	
		.s3-p-p {
			text-align: center;
		}
	}

	.logo-p1 {
		color: #00D5FF;
		font-size: 24px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.logo-p2 {
		color: #00D5FF;
		font-size: 16px;
		font-family: "Montserrat";
		line-height: 5px;
		font-weight: bold;
	}

	.navbar-header {
		padding-top: 25px;
	}

	#bs-example-navbar-collapse-1 > ul {
		padding-top: 35px;
		float: right;
	}

	body > div > header > nav {
		height: 110px;
		border: none!important;
		border-bottom: 1px solid rgba(126, 132, 128, 0.3)!important;
	}
}

@media screen and (max-width: 991px) {
	.s4-p-h3 {
		color: #000;
	}

	.s4-p-p {
		color: #000;
	}

	.s4-p-p2 {
		color: #000;
	}
}


@media screen and (min-width: 1200px) {
	.pict-1 {
		text-align: center;
	}

	.pict-2, .pict-3 {
		padding-left: 20px;
	}	

	.s4-p-h3 {
		padding-left: 15px;
	}

	.s4-p-p {
		padding-left: 15px;
	}

	.section-four > .s4-p-p2 {
		padding-left: 5px;
	}

	.text-2, .text-3 {
		padding-left: 8px;
	}

	.text-1 > p {
		padding-left: 3px;
	}

	.section-one {
		padding-top: 385px;

		.s1-p-h3 {
			font-size: 48px;
		}
		
		.s1-p-p {
			font-size: 18px;
		}

		.s1-div3 {
			margin-bottom: 80px;
		}
	}


	.s4-div2 {
		padding-top: 80px;
		padding-bottom: 60px;

		.s4-p-h3 {
			font-size: 26px;
		}

		.s4-p-p {
			font-size: 18px;
			font-family: "Helvetica";
		}

		.s4-p-p2 {
			font-size: 14px;
		}
	}

	.section-five {
		padding-top: 70px;

		.s5-p-h3 {
			font-size: 24px;
		}

		.s5-p-p {
			font-size: 16px;
		}

		.s5-div3 {
			margin-top: 30px;
			margin-bottom: 60px;
		}
	}

	.s3-p-p {
		font-size: 24px;
	}

	.footer-nav {
		height: 160px;
		padding-top: 60px;
	}

	.footer-ul li a {
		color: #0E0D0D;
		font-family: Montserrat;
	}
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
	.section-one {
		padding-top: 345px;

		.s1-p-h3 {
			font-size: 42px;
		}
		
		.s1-p-p {
			font-size: 16px;
		}

		.s1-div3 {
			margin-bottom: 70px;
		}
	}

	.s4-div2 {
		padding-top: 90px;
		padding-bottom: 65px;
		padding-left: 20px;

		.s4-p-h3 {
			font-size: 26px;
		}

		.s4-p-p {
			font-size: 16px;
			font-family: "Montserrat";
		}

	}

	.s4-div2.col-md-6 > div.col-md-11 > div.col-md-9.text-1 > p {
		padding-left: 12px;
	}

	.section-five {
		padding-top: 60px;

		.s5-p-h3 {
			font-size: 22px;
		}

		.s5-p-p {
			font-size: 14px;
		}

		.s5-div3 {
			margin-top: 30px;
			margin-bottom: 50px;
		}
	}

	.footer-ul > li > a {
		font-size: 13px;
	}

	#bs-example-navbar-collapse-1 > ul {
		padding-right: 0px;

		li > a {
			font-size: 13px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	.s3-p-p {
		font-size: 21px;
	}

	.footer-nav {
		height: 140px;
		padding-top: 50px;
	}

	.s6-p-p {
		color: #555;
		font-size: 14px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3 {
		color: #555;
		font-size: 13px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3-b {
		color: #333;
		font-size: 14px;
		font-family: "Montserrat";
		font-weight: bold;
	}

	.number {
		color: #242450;
	}

	.s6-image {
		margin-top: 15px;
	}


	.about-p-h3 {
		color: #222;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 15px;
	}

	.about-p-h3-2 {
		color: #444;
		font-family: "Montserrat";
		font-weight: bold;
	}

	.about-p-h3-3 {
		color: #000;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 20px;
	}

	.about-p-p {
		font-size: 14px;
		letter-spacing: 0.4px;
		word-spacing: 0.4px;
	}

	.fl-r {
		float: right;
	}

	.padding {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 15px;
	}

	.padding2 {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 35px;
	}

	.italic {
		font-size: 15px;
		font-style: italic;
		text-decoration: underline;
	}


	.m1 {
		margin-top: 30px;
	}

	.m2 {
		margin-top: 20px;
	}

	.m3 {
		margin-bottom: 30px;
	}

	.links a {
		color: #001B5E!important;
		font-size: 15px;
		font-weight: bolder;
		font-family: "Helvetica";
	}

	.links a:hover {
		color: #0094B2!important;
		text-decoration: none;
	}

	.bet-v {
		margin-top: 30px;
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	.section-one {
		padding-top: 325px;

		.s1-p-h3 {
			font-size: 40px;
		}
		
		.s1-p-p {
			font-size: 14px;
		}

		.s1-div3 {
			margin-bottom: 60px;
		}
	}

	.s4-div2 {
		padding-top: 80px;
		padding-bottom: 55px;
		padding-left: 15px;

		.s4-p-h3 {
			font-size: 24px;
			text-align: center;
		}

		.s4-p-p {
			font-size: 16px;
			font-family: "Montserrat";
			text-align: center;
		}

	}

	.s4-div2.col-md-6 > div.col-md-11 > div.col-md-9.text-1 > p {
		padding-left: 12px;
	}

	.footer-ul > li > a {
		font-size: 12px;
	}

	.footer-wrap {
		padding-right: 0px!important;
	}

	#bs-example-navbar-collapse-1 > ul {
		padding-top: 25px;
		padding-right: 0px;

		li > a {
			font-size: 13px;
			padding-left: 8px;
			padding-right: 8px;
		}
	}

	.navbar-header {
		padding-top: 15px;
	}

	.for-sm {
		margin-top: 40px;
	}

	.s3-p-p {
		font-size: 19px;
	}

	.footer-nav {
		height: 120px;
		padding-top: 40px;
	}
	
	.bg-for-img {
		background: rgba(255, 255, 255, 0.45);
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.text-1 {
		margin-top: 40px;
	}

	.text-2, .text-3 {
		margin-top: 15px;
	}

	.pict-1 {
		margin-top: 20px;
	}

	.section-two {
		padding-top: 75px;
		padding-bottom: 55px;
	}

	.section-three {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.section-five {
		padding-top: 60px;

		.s5-p-h3 {
			font-size: 22px;
		}

		.s5-p-p {
			font-size: 14px;
		}

		.s5-div3 {
			margin-top: 30px;
			margin-bottom: 50px;
		}
	}

	.logo-p1 {
		color: #00D5FF;
		font-size: 22px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.logo-p2 {
		color: #00D5FF;
		font-size: 14px;
		font-family: "Montserrat";
		line-height: 5px;
		font-weight: bold;
	}

	.navbar-header {
		float: none;

		a {
			display: block;
			text-align: center;
			margin: 0px auto;
			float: none;
		}
	}

	body > div > header > nav {
		height: 140px;
		border: none!important;
		border-bottom: 1px solid rgba(126, 132, 128, 0.3)!important;
	}

	.s6-p-p {
		color: #555;
		font-size: 13px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3 {
		color: #555;
		font-size: 12px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3-b {
		color: #333;
		font-size: 13px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.number {
		color: #242450;
	}

	.s6-image {
		margin-top: 15px;
	}

	.about-page {
		padding-top: 140px;
	}

	.about-p-h3 {
		color: #222;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 15px;
	}

	.about-p-h3-2 {
		color: #444;
		font-family: "Montserrat";
		font-weight: bold;
	}

	.about-p-h3-3 {
		color: #000;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 20px;
	}

	.about-p-p {
		color: #555;
		letter-spacing: 0.4px;
		word-spacing: 0.4px;
	}

	.fl-r {
		float: right;
	}

	.padding {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 15px;
	}

	.padding2 {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 35px;
	}

	.italic {
		font-size: 15px;
		font-style: italic;
		text-decoration: underline;
	}

	.m1 {
		margin-top: 30px;
	}

	.m2 {
		margin-top: 20px;
	}

	.m3 {
		margin-bottom: 30px;
	}

	.links a {
		color: #001B5E!important;
		font-size: 15px;
		font-weight: bolder;
		font-family: "Helvetica";
	}

	.links a:hover {
		color: #0094B2!important;
		text-decoration: none;
	}

	.bet-v {
		margin-top: 30px;
		margin-bottom: 20px;
	}
}


@media screen and (min-width: 768px) {
	.video {
		width: 520px;
		height: 320px;
	}
}


@media screen and (max-width: 767px) {
	.section-one {
		background-image: url(../../dist/images/Background-xs.jpg);
		padding-top: 210px;

		.s1-p-h3 {
			font-size: 34px;
			text-align: center;
		}
		
		.s1-p-p {
			font-size: 15px;
			text-align: center!important;
		}

		.s1-div3 {
			margin-bottom: 50px;
		}
	}

	.s4-div2.col-md-6 > div.col-md-11 > div.col-md-9.text-1 > p {
		padding-left: 12px;
	}

	.section-five {
		padding-top: 50px;

		.s5-p-h3 {
			font-size: 20px;
		}

		.s5-p-p {
			font-size: 13px;
		}

		.s5-div3 {
			margin-top: 25px;
			margin-bottom: 45px;
		}
	}

	.footer-ul > li > a {
		font-size: 12px;
	}

	#bs-example-navbar-collapse-1 {
		border: none;
		position: absolute;
		width: 100%;
		z-index: 100;
	}

	#bs-example-navbar-collapse-1 > ul {
		padding-right: 0px;
		text-align: center;
		background-color: #2EB5D0;
		margin-top: 0;
		
		li {
			border-bottom: 1px solid #387C8A;

			&:last-child {
				border-bottom:none;
			}

			&:hover {
				background-color:#00D5FF;
			}
		}

		li > a {
			font-size: 14px;
			padding-left: 8px;
			padding-right: 8px;
		}

		#bs-example-navbar-collapse-1 > ul > li:last-child {
			border-bottom: none!important;
		}
	}	

	.navbar-header {
		padding-top: 15px;
		padding-bottom: 20px;
	}

	.for-sm, .for-xs {
		margin-top: 30px;
	}

	.section-three {
		padding-top: 30px;
		padding-bottom: 50px;

		.s3-p-p {
			font-size: 22px;
			text-align: center;
			margin-bottom: 30px;
		}	
	}

	.footer-nav {
		height: 120px;
		padding-top: 40px;
	}
	
	.bg-for-img {
		background: rgba(255, 255, 255, 0.45);
		width: 100%;
		height: 100%;
		position: absolute;
	}

	.section-two {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.btn-primary {
		display: block;
		margin: 0px auto;
	}

	.al-center {
		display: block;
		margin: 0px auto;
	}

	.section-four {
		background-size: cover;
		padding-bottom: 30px;


		.s4-div2 {
			padding-top: 60px;
			padding-bottom: 45px;
			padding-left: 15px;
		}
		
		.s4-p-h3 {
			text-align: center;
		}

		.s4-p-p {
			font-size: 16px;
			font-family: "Montserrat";
			text-align: center;
		}


		.text-1, .text-2, .text-3, .pict-1, .pict-2, .pict-3 {
			text-align: center;
			margin-top: 15px;
		}

		.s4-div2.col-sm-offset-2.col-sm-8.col-md-offset-0.col-md-6 > div.col-xs-12.col-sm-12.col-md-11,
		.s4-div2.col-sm-offset-2.col-sm-8.col-md-offset-0.col-md-6 > div.col-xs-12.col-sm-12.col-md-12 {
			padding-left: 0px!important;
		}
	}

	.logo-p1 {
		color: #00D5FF;
		font-size: 23px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.logo-p2 {
		color: #00D5FF;
		font-size: 16px;
		font-family: "Montserrat";
		line-height: 5px;
		font-weight: bold;
	}

	.s3-div5, .s3-div6 {
		margin-top: 15px;
	}

	.navbar-header > button {
		margin-top: 15px;
		border-color: #00D5FF!important;
	}

	body > div > header > nav > div > div.navbar-header > button > span {
		background-color: #00D5FF!important;
	}

	.about-page {
		padding-top: 120px;
		text-align: center;
	}

	.about-p-h3 {
		color: #222;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 15px;
	}

	.about-p-h3-2 {
		color: #444;
		font-family: "Montserrat";
		font-weight: bold;
	}

	.about-p-h3-3 {
		color: #000;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 20px;
	}

	.about-p-p {
		color: #555;
		letter-spacing: 0.4px;
		word-spacing: 0.4px;
	}

	.fl-r {
		margin-bottom:20px;
	}

	.italic {
		font-size: 15px;
		font-style: italic;
		text-decoration: underline;
	}

	.m1 {
		margin-top: 30px;
	}

	.m2 {
		margin-top: 20px;
	}

	.m3 {
		margin-bottom: 30px;
	}
	
	.links {
		color: #001B5E!important;
		font-size: 15px;
		font-weight: bolder;
		font-family: "Helvetica";
	}

	.links a {
		color: #001B5E!important;
		font-size: 15px;
		font-weight: bolder;
		font-family: "Helvetica";
	}

	.olame a {
		color: #001B5E!important;
		font-size: 20px;
		font-weight: bolder;
		font-family: "Helvetica";
		text-align: center;
	}

	.links a:hover, .olame a:hover {
		color: #0094B2!important;
		text-decoration: none;
	}

	.bet-v {
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.video {
		height: 250px;
	}
}

/*FOOTER*/

.footer-nav {
	margin-bottom: 0px;
	background-color: #FFF;
	border-top: 1px solid #00D5FF;
}

.footer-ul li a {
	color: #0E0D0D;
	font-family: "Montserrat";
}





@media screen and (min-width: 1200px) {
	.page-section-one {
		padding-top: 200px;
	}

	.sidebar-h3 {
		font-size: 16px;
		font-weight: bolder;
	}

	.text-align {
		text-align: center;
	}

	.block-one {
		border: 2px solid #000;
	}

	.sidebar-p {
		padding-top: 10px;
		font-size: 15px;
	}

	.s6-p-p {
		color: #555;
		font-size: 16px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3 {
		color: #555;
		font-size: 14px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.s6-p-h3-b {
		color: #333;
		font-size: 16px;
		font-family: "Montserrat";
		font-weight: bolder;
	}

	.number {
		color: #242450;
	}

	.none-p {
		margin-bottom: 5px;
	}

	.about-page {
		padding-top: 120px;
	}

	.about-p-h3 {
		color: #222;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 15px;
	}

	.about-p-h3-2 {
		color: #444;
		font-family: "Montserrat";
		font-weight: bold;
	}

	.about-p-h3-3 {
		color: #000;
		font-family: "Montserrat";
		font-weight: bold;
		line-height: 20px;
	}

	.about-p-p {
		color: #555;
		letter-spacing: 0.4px;
		word-spacing: 0.4px;
	}

	.fl-r {
		float: right;
	}

	.padding {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 20px;
	}

	.padding2 {
		padding-top: 5px;
		padding-left: 20px;
		padding-bottom: 35px;
	}

	.italic {
		font-size: 15px;
		font-style: italic;
		text-decoration: underline;
	}

	.m1 {
		margin-top: 30px;
	}

	.m2 {
		margin-top: 20px;
	}

	.m3 {
		margin-bottom: 30px;
	}

	.links a {
		color: #001B5E!important;
		font-size: 15px;
		font-weight: bolder;
		font-family: "Helvetica";
	}

	.links a:hover {
		color: #0094B2!important;
		text-decoration: none;
	}

	.bet-v {
		margin-top: 30px;
		margin-bottom: 20px;
	}
}

	.block-center {
		display: block;
		margin: 0px auto;
	}

	.none {
		padding-left: 0px!important;
		padding-right: 0px!important;
	}

	.border {
		border: 2px solid;
	}

	.mbn {
		margin-bottom: 0px;
	}
	
	.video {
		display: block;
		margin: 0px auto;
	}