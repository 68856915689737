/*Modal*/

.modal-content {
	background-color: #f8f8f8;
	border-radius:15px;
	
	h3 {
		color:#00D5FF;
	}

	.close {
		margin-top: -8px;
		font-size: 35px;
		margin-right: -35px;
	}
	
	.modal-window {
		text-align: center;
		padding: 15px 50px;

		.s1-modal-h3 {
			font-weight: bolder;
			font-family: "Montserrat";
		}
	}

	.modal-body {
		padding:15px 50px;
	}

	.submit-button {
		margin: 30px 0 20px;
	}
}


/*Map-Section*/

.section-map {
	position:relative;
}

.acf-map {

	iframe {
		width:100%;
		height:300px;
	}
}

.map-container {
	background-color: #fff;
	padding: 30px 15px 15px;
}

.gm-style .place-card-large {
	display:none;
}

@media screen and (max-width: 767px) {
	.scroll-text {
		text-align:center;
	}

	.img-col {
		margin-bottom: 20px;

		img {
			margin:0 auto;
		}
	}
}

@media screen and (min-width: 768px) {

	footer {

		.nav li {
			width:auto;

			a {
				padding-left:0;
				padding-right:0;
			}
		}
	}

	.map-container {
		position: absolute;
		z-index: 1;
		top: 30%;
		right: 10%;
		margin-bottom:0;
		padding: 35px 5px 35px 35px;
		border-radius: 15px;
		background-color: rgba(255,255,255,0.9);
		box-shadow: 0 2px 5px rgba(0,0,0,0.1);
	}

	.scroll-text {

		.cart-title {
			@media screen and (max-width: 991px) {
				font-size: 16px;
			}
			margin-top:0;
			font-weight:bold;
			color: #387C8A;
			padding-bottom: 10px;
			border-bottom: 2px solid rgba(0,0,0,0.1);
			display:inline-block;
		}

		address {
			margin-bottom:0;
			color: #666;
		}

		p {
			margin-bottom:5px;
			font-size: 13px;

			@media screen and (max-width: 991px) {
				font-size: 11px;
			}

			span {
				font-size: 15px;
				font-weight:bold;

				@media screen and (max-width: 991px) {
					font-size: 13px;
				}
			}
		}

		.phones {
			color: #387C8A;
			margin-top: 20px;
			margin-bottom: 0;
			font-size: 15px;
			font-weight:bold;

			@media screen and (max-width: 991px) {
				font-size: 12px;
			}
		}
	}

	.acf-map {
		position:relative;

		iframe {
			width:100%;
			height:500px;
		}
	}
	
}


/*Navbar Brand*/

.navbar-brand {
	text-shadow: 0 1px 1px rgba(0,0,0,.47);
}


/*Popup*/

.popover {
	border-radius: 10px;
	background-color: rgb( 248, 248, 248 );
	box-shadow: 14.5px 14px 40px 0px rgb(167, 160, 160);
	border: 1px solid rgba(220, 219, 219, 0.2);
}


/*Header-1*/

.header-1 {
	position:relative;

	.background {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-image: url(../../dist/images/Background.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center 50%;
	}

	.navbar {
		background-color: rgba(0, 27, 33, 0.42);
		z-index: 2;
		border-radius:0;
	}
}


/*Sidebar*/

aside {
	text-align:center;
	padding-left:40px;

	@media (min-width: $screen-sm-min) {
		padding-left: 30px;	
	}

	section {
		margin-bottom:30px;
		padding: 20px 0;
		@media (min-width: $screen-sm-min) {
			padding: 30px 0 20px;
		}
	}


	
	h3 {
		font-family: Montserrat;
		padding-bottom: 10px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.04);
		display: inline-block;
		margin-top: 0;
		font-size: 16px;
	}

	p {
		margin:0;
	}

	img {
		margin:20px auto 20px;
	}
}


/*Footer*/

@media (min-width: 768px) {

	.footer-ul {
		float: none;
		margin: 0 auto;
		text-align: center;
		list-style: none;
		
		li {
			display:inline-block;
			float:none;
		}
	}
}


.text-danger {
    color: #a94442 !important;
}

.center-xs {
	margin: 0 auto;
}