// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.sidebar-primary .main {
    @media (min-width: $screen-lg-min) { 
    	    padding-right: 60px; 
    }
}

.main, .sidebar {
		margin-top: 15px;
	margin-bottom: 15px;
	@media (min-width: $screen-sm-min) {
		margin-top: 30px;
	margin-bottom: 30px;
	}
}
