// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Colors
$brand-primary:         #00D5FF;

// Fonts

$headings-font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;